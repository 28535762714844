import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Container, Links, StyledLink, CloseButton, Background } from "./style"

export const BurgerMenu = ({ setActive }) => {
  return (
    <Container>
      <Background>
        <StaticImage
          src="../../images/burger-background.png"
          alt="person with laptop image"
          placeholder="blurred"
          style={{ height: "100%" }}
        />
      </Background>
      <CloseButton onClick={() => setActive(false)}>
        <StaticImage
          src="../../images/close-button.png"
          alt="person with laptop image"
          width={32}
          placeholder="blurred"
        />
      </CloseButton>
      <Links>
        <StyledLink
          text="About"
          activeStyle={{ borderBottom: "1px solid black" }}
          to="/"
        >
          About
        </StyledLink>
        <StyledLink
          text="Products"
          activeStyle={{ borderBottom: "1px solid black" }}
          to="/products"
        >
          Products
        </StyledLink>
        <StyledLink
          text="Case studies"
          activeStyle={{ borderBottom: "1px solid black" }}
          to="/case-studies"
        >
          Case studies
        </StyledLink>
        <StyledLink
          text="Blog"
          activeStyle={{ borderBottom: "1px solid black" }}
          to="/blog"
        >
          Blog
        </StyledLink>
      </Links>
    </Container>
  )
}
