import styled from "styled-components"
import { Link } from "gatsby"

export const Container = styled.nav`
  height: 100px;
`

export const MobileWrapper = styled.div`
  display: none;
  @media (max-width: 890px) {
    display: flex;
    align-items: center;
    height: 100%;
  }
`

export const MobileLogo = styled(Link)`
  position: absolute;
  top: 24px;
  left: 24px;
  text-decoration: none;
  border-bottom: none;
  &:hover {
    border-bottom: none;
  }

  &:active {
    border-bottom: none;
    margin-bottom: 0;
  }
`

export const Logo = styled(Link)`
  justify-self: start;
  text-decoration: none;
  border-bottom: none;
  margin-top: -16px;
  &:hover {
    border-bottom: none;
  }

  &:active {
    border-bottom: none;
    margin-bottom: 0;
  }
`

export const DesktopWrapper = styled.div`
  height: 100px;
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  align-items: center;
  justify-items: end;
  padding: 0 100px;
  @media (max-width: 890px) {
    display: none;
  }
`

export const Burger = styled.button`
  position: absolute;
  top: 24px;
  right: 24px;
`

export const Links = styled.div`
  display: flex;
  justify-content: center;
  gap: 25px;
  flex-direction: row;
  justify-self: center;
`

export const StyledLink = styled(Link)`
  text-decoration: none;
  text-align: center;
  font-size: 16px;
  color: #000;
  padding: 0 2px;
  &:before {
    display: block;
    content: attr(text);
    font-weight: 600;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }
`
