import { createGlobalStyle } from "styled-components"
import "@fontsource/open-sans"
import "@fontsource/open-sans/500.css"
import "@fontsource/open-sans/600.css"
import "@fontsource/open-sans/700.css"
import "@fontsource/fahkwang"
import "@fontsource/fahkwang/500.css"

export const GlobalStyle = createGlobalStyle`
    
    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        font-family: 'Open Sans', sans-serif;
    }

    p {
        font-family: 'Open Sans', sans-serif;
        font-size: 16px;
    }
    
    h1 {
        font-size: 96px;
        font-family: 'Fahkwang', sans-serif;
        font-weight: 500 ;
  letter-spacing: 0.05em;
}

h2 {
        letter-spacing: 0.05em;
        font-family: 'Fahkwang', sans-serif;
        font-weight: 500 ;
        font-size: 32px;
    }

    h3 {
        font-family: 'Fahkwang', sans-serif;
        font-weight: 500 ;
    }

    h4 {
        font-family: 'Fahkwang', sans-serif;
        font-weight: 500 ;
    }
    
    h5 {
        font-family: 'Open Sans', sans-serif;
        font-size: 18px;
        font-weight: 400 ;
    }

    body,
    html,
    #root {
        height: 100%;
        scroll-behavior: smooth;
    }

    button, input[type="submit"] {
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
}

    a,
    button {
        color: black;
        text-decoration: none;
        font-weight: normal;
        cursor: pointer;
        border-bottom: 1px solid #00000000;
    }

    a:hover {
        border-bottom: 1px solid black;
    }

    a:active {
        border-bottom: 2px solid black;
        margin-bottom: -1px;
        font-weight: 600;
    }

    .slick-prev,
    .slick-next {
        width: 38px !important;
        height: 38px !important;
    }
    
    @media (max-width: 890px) {
        h1 {
            font-size:36px;
            line-height:64px;
        }
        
        h2 {
            font-size: 24px;
        }
        
        h3 {
        }
        
        h4 {
            font-size: 16px;
        }
        
        h5 {
            font-size: 18px;
        }
        .slick-prev,
        .slick-next {
            width: 18px !important;
            height: 18px !important;
        }
    }
    
    `
