import React, { useState } from "react"
import {
  Container,
  Links,
  StyledLink,
  DesktopWrapper,
  MobileWrapper,
  Burger,
  Logo,
  MobileLogo,
} from "./style"
import { Button } from "../Button"
import { StaticImage } from "gatsby-plugin-image"
import { BurgerMenu } from "../BurgerMenu"

export const Navbar = () => {
  const [isBurgerActive, setBurgerActive] = useState(false)

  return (
    <Container>
      <DesktopWrapper>
        <Logo to="/">
          <StaticImage
            src="../../images/oakdevices-logo.png"
            alt="logo"
            height="30"
            placeholder="blurred"
          />
        </Logo>
        <Links>
          <StyledLink
            text="About"
            activeStyle={{ borderBottom: "1px solid black" }}
            to="/"
          >
            About
          </StyledLink>
          <StyledLink
            text="Products"
            activeStyle={{ borderBottom: "1px solid black" }}
            to="/products"
          >
            Products
          </StyledLink>
          <StyledLink
            text="Case studies"
            activeStyle={{ borderBottom: "1px solid black" }}
            to="/case-studies"
          >
            Case studies
          </StyledLink>
          <StyledLink
            text="Blog"
            activeStyle={{ borderBottom: "1px solid black" }}
            to="/blog"
          >
            Blog
          </StyledLink>
        </Links>
        <Button to="/#contact-us" text="Contact" />
      </DesktopWrapper>
      <MobileWrapper>
        {!isBurgerActive ? (
          <>
            <Burger onClick={() => setBurgerActive(true)}>
              <StaticImage
                src="../../images/menu-burger.png"
                alt="burger"
                width={32}
                placeholder="blurred"
              />
            </Burger>
            <MobileLogo to="/">
              <StaticImage
                src="../../images/oakdevices-logo.png"
                alt="burger"
                height={32}
                placeholder="blurred"
              />
            </MobileLogo>
          </>
        ) : (
          <BurgerMenu setActive={setBurgerActive} />
        )}
      </MobileWrapper>
    </Container>
  )
}
