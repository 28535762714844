import styled from "styled-components"
import { Link } from "gatsby"

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
`

export const StyledLink = styled(Link)`
  margin: 0 auto;
  text-decoration: none;
  text-align: center;
  font-size: 16px;
  color: #000;
  padding: 0 2px;
  &:before {
    display: block;
    content: attr(text);
    font-weight: 600;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }
`

export const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -2;
`

export const Links = styled.div`
  display: flex;
  justify-content: center;
  gap: 45px;
  flex-direction: column;
  justify-self: center;
  padding: 45px 0;
`

export const CloseButton = styled.button`
  position: absolute;
  top: 24px;
  right: 24px;
`
