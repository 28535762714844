import styled from "styled-components"
import { Link } from "gatsby"

export const Container = styled(Link)`
  display: inline-block;
  padding: 10px 15px;
  border-radius: 25px;
  font-size: 1.1em;
  border: none;
  background-image: linear-gradient(90deg, #f23fc5, #f67bb2, #a994dc);
  &:hover {
    filter: drop-shadow(0px 2px 6px rgba(246, 123, 178, 0.4));
    border: none;
  }
  &:active {
    border: none;
    background-image: linear-gradient(
      81.84deg,
      #952178 -200.74%,
      #c65286 53.5%,
      #a28cd7 230.36%
    );
  }
`

export const Text = styled.h5`
  margin: 0;
  color: #fff;
  ${Container}:active & {
    -webkit-text-fill-color: #fff;
  }
`
