import React from "react"
import { Navbar } from "../Navbar"
import { Footer } from "../Footer"
import { Container, Content } from "./style"
import { GlobalStyle } from "../../styles/GlobalStyles"

export const Layout = ({ children }) => {
  return (
    <Container>
      <GlobalStyle />
      <Navbar />
      <Content>{children}</Content>
      <Footer />
    </Container>
  )
}
